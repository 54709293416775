<template>
	<div class="container">
		<input type="button" class="btn btn-primary text-center" value="Click" @click="get">
	</div>
</template>

<script>
	import Api from '@/helpers/api';
	import test from '@/services/test.service';

	export default {
		methods: {
			get() { 
                let context = this; 
                
                Api(context, test.get()).onSuccess(function(response) {    
                    console.log(response.data.data.data.data)
                }).onError(function(error) {                    
                    
                })
                .call()
            }
		}
	}
</script>